import { PrimaryPassengerFormData } from "../../flights/checkout/primary-passenger-detail-form/primary-passenger-form-data";
import { FlightsCheckoutState } from "../../flights/checkout/checkout.state";
import { TravelType } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";

export class ResetFormController {
  static $inject = [
    "$rootScope",
    "$timeout",
    "FlightsCheckoutState",
    "GlobalStateService",
  ];

  // bindings
  fields: string[];
  data: PrimaryPassengerFormData;
  checkoutForm: any;
  onUpdate: (obj: { data: PrimaryPassengerFormData }) => void;

  constructor(
    private $rootScope: any,
    private $timeout: any,
    private flightsCheckoutState: FlightsCheckoutState,
    private globalStateService: GlobalStateService,
  ) {
    this.flightsCheckoutState = flightsCheckoutState;
  }

  resetUserDetails(event: Event): void {
    event.preventDefault();
    this.fields.forEach(field => this.resetField(field));
    if (this.checkoutForm) {
      this.checkoutForm.$setPristine();
      this.checkoutForm.$setUntouched();
    }
  }

  private resetField(field): string {
    switch (this.globalStateService.travelType) {
      case TravelType.HOTELS:
        field = this.buildEmptyFormDataForHotels(field);
        break;
      case TravelType.CARS:
        field = this.buildEmptyFormDataForCars(field);
        break;
      case TravelType.FLIGHTS:
        field = this.buildEmptyFormDataForFlights();
        this.onUpdate({ data: field });
        break;
    }

    this.setPristineFields();
    return field;
  }

  private setPristineFields(): void {
    if (!this.checkoutForm) {
      return;
    }

    this.checkoutForm.submitted = false;
    this.checkoutForm.$setUntouched();
    if (this.checkoutForm) {
      this.$timeout(() => {
        this.checkoutForm.$setPristine();
      });
    }
  }

  private buildEmptyFormCommonData(field): any {
    field.title = "";
    field.firstName = "";
    field.lastName = "";
    field.email = "";
    field.phoneNumber = "";
    field.phoneCode = "";
    field.rememberMe = false;

    return field;
  }

  private buildEmptyFormDataForHotels(field): any {
    this.buildEmptyFormCommonData(field);
    field.specialRequests = "";

    return field;
  }

  private buildEmptyFormDataForCars(field): any {
    this.buildEmptyFormCommonData(field);
    field.country_residence = "";
    field.flight_number = "";
    field.carRentalTerms = false;

    return field;
  }

  private buildEmptyFormDataForFlights(): PrimaryPassengerFormData {
    return {
      commonData: this.flightsCheckoutState.buildEmptyCommonData(),
      phoneDetails: {
        countryCode: {
          id: null,
          text: null,
        },
        number: null,
      },
      email: null,
    };
  }
}
