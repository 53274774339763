import { Bindings } from "booking_app/types";
import { ResetFormController } from "./reset-form.controller";

export class ResetFormComponent {
  bindings: any = {
    fields: Bindings.ONE_WAY,
    data: Bindings.ONE_WAY,
    checkoutForm: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
  };

  controller: any = ResetFormController;
  template: string = `
    <div class="reset-form">
      <a ng-click="$ctrl.resetUserDetails($event)" translate="Reset form"> </a>
    </div>
  `;
}

angular.module("BookingApp").component("resetForm", new ResetFormComponent());
