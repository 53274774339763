require('../cars-setup');
require('../flights-setup');
require('../hermes-setup');

global.loadWlDeps = function() {
  require('angular-counter/js/angular-counter');
}

global.wlAngularDeps = [
  'ngAnimate',
];


global.loadAppDeps = function () {
  loadCarsAppDeps();
  loadFlightsAppDeps();
  loadHermesDeps();
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/fab/services');
  require('../../assets/javascripts/whitelabel/fab/locales');
  require('../../assets/javascripts/whitelabel/fab/controllers/fab-controller');
  require('../../assets/javascripts/whitelabel/fab/components');
}

require('../application');
