import { HeaderUrlLink } from "booking_app/constants/header-url-link";
import { CurrentPage, TravelType } from "booking_app/types";

export class HeaderMenuList {
  static $inject = [
    "$window",
    "KaligoConfig",
    "$rootScope",
  ];

  public headerList: HeaderUrlLink[];

  constructor(
    private $window: any,
    private kaligoConfig: any,
    private $rootScope: any,
  ) {
    this.setupHeaderList();
  }

  setupHeaderList(): void {
    this.headerList = [
      {
        title: "wl.travel_benefits",
        active: false,
        links: [
          {
            title: "wl.exclusive_partner_benefits",
            siteUrl: `${this.$rootScope.rdUrl}/partners`,
          },
          {
            title: "wl.travel_card_benefits",
            siteUrl: `${this.$rootScope.rdUrl}/card-benefits`,
          },
        ],
      },
      {
        title: "wl.travel_booking",
        active: false,
        links: [
          {
            title: "Hotels",
            siteUrl: `${this.kaligoConfig.FAB_URL}/hotels`,
            redirect: true,
            travelType: TravelType.HOTELS,
            activePages: [CurrentPage.HOME, CurrentPage.HOTEL_HOME, CurrentPage.SEARCH_RESULT,
                          CurrentPage.HOTEL_DETAIL, CurrentPage.CHECKOUT, CurrentPage.CONFIRMATION],
          },
          {
            title: "Flights",
            siteUrl: `${this.kaligoConfig.FAB_URL}/flights`,
            redirect: true,
            travelType: TravelType.FLIGHTS,
            activePages: [CurrentPage.FLIGHT_HOME, CurrentPage.FLIGHT_RESULT, CurrentPage.FLIGHT_DETAIL,
                          CurrentPage.FLIGHT_CHECKOUT, CurrentPage.FLIGHT_SUCCESS],
          },
          {
            title: "Cars",
            siteUrl: `${this.kaligoConfig.FAB_URL}/cars`,
            redirect: true,
            travelType: TravelType.CARS,
            activePages: [CurrentPage.CAR_HOME, CurrentPage.CAR_RESULT, CurrentPage.CAR_DETAIL,
                          CurrentPage.CAR_CHECKOUT, CurrentPage.CAR_SUCCESS],
          },
          {
            title: "wl.complimentary_night",
            siteUrl: `${this.kaligoConfig.FAB_URL}/complimentary_nights`,
            redirect: true,
            travelType: TravelType.HOTELS,
            activePages: [CurrentPage.COMPLIMENTARY_NIGHTS],
          },
        ],
      },
      {
        title: "txt.redeem_miles",
        active: false,
        links: [
          {
            title: "wl.travel_booking",
            redirect: true,
            travelType: TravelType.HOTELS,
            siteUrl: `${this.kaligoConfig.FAB_URL}`,
          },
          {
            title: "wl.gift_cards",
            siteUrl: `${this.$rootScope.rdUrl}/gift_card/listing`,
          },

        ],
      },
      {
        title: "wl.miles_transfer",
        active: false,
        links: [
          {
            title: "wl.miles_transfer",
            siteUrl: `${this.$rootScope.rdUrl}/transfer/programs`,
          },

          {
            title: "wl.cash_back",
            siteUrl: `${this.$rootScope.rdUrl}/redeem_cash/checkout`,
          },
        ],
      },
      {
        title: "My Account",
        active: false,
        requireLoggedIn: true,
        links: [
          {
            title: "wl.miles_activity",
            siteUrl: `${this.$rootScope.rdUrl}/points_activity`,
          },
          {
            title: "Manage bookings",
            redirect: true,
            siteUrl: `${this.$window.location.origin}/account/booking`,
            activePages: [CurrentPage.MANAGE_BOOKING],
          },
        ],
      },
    ];
  }
}
angular.module("BookingApp").service("HeaderMenuList", HeaderMenuList);
