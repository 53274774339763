declare var Rollbar: any;

import { Hermes, HermesError } from "hermes";

import { Modal, UserAuthOptions } from "booking_app/types";
import { ModalManager } from "booking_app/components/common";
import { LoginService } from "booking_app/services/login";
import { AppSettings } from "booking_app/values/app-settings";
import { SimpleModalService } from "booking_app/services/simple-modal.service";

export class HermesService {
  static readonly SESSION_EXPIRED_ERROR = "GH2022";

  static $inject = [
    "AppConfig",
    "AppSettings",
    "LoginService",
    "ModalManager",
    "$modal",
    "$translate",
    "SimpleModalService",
  ];

  private hermes: Hermes;

  constructor(
    private appConfig: any,
    private appSettings: AppSettings,
    private loginService: LoginService,
    private modalManager: ModalManager,
    private $modal: any,
    private $translate: any,
    private simpleModalService: SimpleModalService,
  ) { }

  initHermes(): void {
    const { sessionCheckIntervalInSeconds, timeout, promptTimeout } = this.appSettings.hermesConfig;

    this.hermes = new Hermes({
      ...this.authOptions(),
      sessionCheck: {
        pollInterval: sessionCheckIntervalInSeconds,
      },
      idleCheck: {
        onTimeout: () => this.onIdleTimeout(),
        onActivityRestored: () => this.onIdleActivityRestored(),
        timeout,
        promptTimeout,
        dialogElementId: "user-idle-dialog",
      },
      mode: "iframe",
    });

    this.startIdleCheck();
    this.startSessionCheck();
  }

  private startIdleCheck(): void {
    this.hermes.startIdleCheck();
  }

  private async startSessionCheck(): Promise<void> {
    const sessions = this.hermes.startSessionCheck();

    try {
      for await (const session of sessions) {
        if (!session.loggedIn) {
          this.openSessionExpiredModalInstance();
        }
      }
    } catch (error) {
      this.handleSessionFailure(error);
    }
  }

  private handleSessionFailure(error: HermesError): void {
    // TODO Temporary skip of HM99 (Timeout) error.
    // TODO This should be removed after local TE was updated to staging GH's domain.
    if (error.code === "HM99") {
      return;
    }

    // session expired error is not a hard failure, we don't need to report it
    if (error.code === HermesService.SESSION_EXPIRED_ERROR) {
      this.openSessionExpiredModalInstance();
    } else {
      this.reportSessionError(error);
      this.loginService.redirectLogout();
    }
  }

  private reportSessionError(error): void {
    if (typeof Rollbar.warning !== "undefined") { // Rollbar not loaded for development ENV
      Rollbar.warning("User session check with GH failed", JSON.stringify(error));
    }
  }

  private onIdleTimeout(): void {
    if (!this.modalManager.isPriorityModalOpen()) {
      this.openIdleModalInstance();
    }
  }

  private onIdleActivityRestored(): void {
    this.modalManager.close();
  }

  private openIdleModalInstance(): void {
    this.modalManager.open({
      animation: true,
      templateUrl: "/html/modals/idle_modal",
      controller: "IdleModal",
      controllerAs: "$ctrl",
      windowClass: "center-modal idle-modal",
      backdrop: "static",
      resolve: {
        hermesInstance: () => this.hermes,
      },
    }, true);
  }

  private openSessionExpiredModalInstance(): void {
    this.simpleModalService.open(Modal.SESSION_EXPIRY).then(() => {
      this.loginService.redirectLogout();
    });
  }

  private authOptions(): UserAuthOptions {
    return this.appConfig.user_auth_options;
  }
}

angular.module("BookingApp").service("HermesService", HermesService);
