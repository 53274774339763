import { FabLanguageSwitcherController } from "./fab-language-switcher.controller";

export class FabLanguageSwitcher {

  controller: any = FabLanguageSwitcherController;
  template: string = `
    <div class="nav fab-language-switcher">
      <a ng-click="$ctrl.changeLocale()">{{$ctrl.localeText()}}</a>
    </div>
  `;
}

angular.module("BookingApp").component("fabLanguageSwitcher", new FabLanguageSwitcher());
