import { Modal } from "booking_app/types/modal";
import { modalSettings } from "booking_app/constants/modal-settings";

modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.modalIconCDNPath = "/fab/icons/icn-warning.svg";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.modalIconCDNPath = "/fab/icons/icn-warning.svg";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.modalIconCDNPath = "/fab/icons/icn-warning.svg";
modalSettings[Modal.GULF_RESIDENCY].content.rejectButtonText = "No, I am not";
modalSettings[Modal.GULF_RESIDENCY].content.resolveButtonText = "Yes, I am a resident";
modalSettings[Modal.SESSION_EXPIRY].content.modalHeader = "wl.fab.session_expire.modal_header";
modalSettings[Modal.SESSION_EXPIRY].content.modalBody = "wl.fab.session_expire.modal_body";
