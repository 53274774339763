import { Fab443LandingPageController } from "./fab443-landing-page.controller";
import { Bindings } from "booking_app/types";

export class Fab443LandingPage {
  bindings: any = {
    data: Bindings.ONE_WAY,
  };

  controller: any = Fab443LandingPageController;
  template: string = `
    <div class="landing-screen-container">

      <div class="search-form-container">
        <hotels-search-form date-icon="'date-icon.svg'" class="fab443-search-form"></hotels-search-form>
      </div>

      <img class="content-container-bg" ng-src="{{ $ctrl.mobileBackgroundImage()}}"></img>
      <div class="content-container">
        <div class="cc-info">

          <div class="cc-info-title">
            {{ 'wl.fab443.cc_info_title' | translate }}
          </div>

          <div class="cc-info-description">
            <img class="cc-info-image" ng-src="{{ $ctrl.ccImageUrl() }}">
            <div>
              <ul>
                <li>{{ 'wl.cc_info_description_1' | translate }}</li>
                <li>{{ 'wl.cc_info_description_2' | translate }}</li>
                <li>{{ 'wl.cc_info_description_3' | translate }}</li>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </div>
  `;
}

angular.module("BookingApp").component("fab443LandingPage", new Fab443LandingPage());
