export const LanguageTextReverseMap: { [key: string]: string }  = {
  en: "العربية",
  ar: "English",
};

export class FabLanguageSwitcherController {
  static $inject = ["$rootScope"];

  private languageTextReverseMap = LanguageTextReverseMap;

  constructor(private $rootScope: any) {}

  changeLocale(): void {
    const updatedLocale: string = this.toggleSelectedLocale();

    this.$rootScope.globalState.changeLocale(updatedLocale);
  }

  localeText(): string {
    return this.languageTextReverseMap[this.$rootScope.selectedLocale.code];
  }

  private toggleSelectedLocale(): string {
    if (this.$rootScope.selectedLocale.code === "en") {
      return "ar";
    } else {
      return "en";
    }
  }

}
