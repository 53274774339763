export class Fab443LandingPageController {
  static $inject = [
    "$rootScope",
  ];

  constructor(
    private $rootScope: any,
  ) { }

  ccImageUrl(): string {
    return `${this.$rootScope.const.cdnImageUrl}/fab443/prod/cc_face.png`;
  }

  mobileBackgroundImage(): string {
    return `${this.$rootScope.const.cdnImageUrl}/fab/images/443-kv-mobile.jpg`;
  }
}
